import clsx from "clsx";
import style from "components/Pager.module.scss";
import times from "lodash/times";
import React from "react";

const Pager = ({
  count,
  activeIndex,
  onClick,
  theme = "dark",
  size = "normal",
}) => {
  const className = clsx(style.root, {
    [style.root___dark]: theme === "dark",
    [style.root___light]: theme === "light",
    [style.root___normal]: size === "normal",
    [style.root___large]: size === "large",
  });

  return (
    <div className={className}>
      {times(count).map((i) => {
        const isActive = i === activeIndex;

        const buttonClassName = clsx(style.pagerButton, {
          [style.pagerButton___isActive]: isActive,
        });

        const handleClick = () => {
          onClick(i);
        };

        return (
          <button
            key={i}
            className={buttonClassName}
            type="button"
            aria-label="Go"
            onClick={handleClick}
          >
            <div className={style.pagerCarat} />
          </button>
        );
      })}
    </div>
  );
};

export default Pager;
