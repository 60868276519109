import React from "react";

const Quotes = () => (
  <svg viewBox="0 0 73 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.11 25.08H17.67C17.86 17.48 22.99 15.01 32.11 14.63V0C10.83 0.570004 0 8.36001 0 26.98V53.58H32.11V25.08ZM72.58 25.08H58.33C58.33 17.48 63.65 15.01 72.58 14.63V0C51.49 0.570004 40.66 8.36001 40.66 26.98V53.58H72.58V25.08Z"
      fill="#199CD9"
    />
  </svg>
);

export default Quotes;
