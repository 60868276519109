import PageControls from "components/PageControls";
import Pager from "components/Pager";
import style from "components/Quotations.module.scss";
import Quotes from "components/Quotes";
import { AnimatePresence, motion } from "framer-motion";
import {
  defaultRootVariants,
  easeInOutCubic,
  easeOutQuint,
} from "lib/constants";
import Image from "next/legacy/image";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

import { wrap } from "@popmotion/popcorn";

const contentVariants = {
  initial: {
    opacity: 0,
    transition: { duration: 1, ease: easeOutQuint },
  },
  animate: {
    opacity: 1,
    transition: { duration: 1, ease: easeOutQuint },
  },
  exit: {
    opacity: 0,
    transition: { duration: 1, ease: easeOutQuint },
  },
};

const cellVariants = {
  initial: {
    transition: {
      ease: easeInOutCubic,
    },
  },
  animate: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

const imageVariants = {
  initial: {
    opacity: 0,
    x: 40,
    transition: {
      duration: 0.5,
      ease: easeOutQuint,
    },
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.75,
      ease: easeOutQuint,
    },
  },
  exit: {
    opacity: 0,
    x: -40,
    transition: {
      duration: 0.75,
      ease: easeOutQuint,
    },
  },
};

const textVariants = {
  initial: {
    opacity: 0,
    x: 20,
    transition: {
      duration: 0.75,
      ease: easeOutQuint,
    },
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: easeOutQuint,
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  exit: {
    opacity: 0,
    x: -80,
    transition: {
      duration: 0.5,
      ease: easeOutQuint,
    },
  },
};

const textElementVariants = {
  initial: {
    opacity: 0,
    x: 20,
    transition: {
      duration: 0.5,
      ease: easeOutQuint,
    },
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: easeOutQuint,
    },
  },
  exit: {
    opacity: 0,
    x: -20,
    transition: {
      duration: 0.5,
      ease: easeOutQuint,
    },
  },
};

const Quotations = ({ cells }) => {
  const [activeCellIndex, setActiveCellIndex] = useState(0);

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
  });

  const activeCell = cells[activeCellIndex];

  const image = activeCell.image[0];

  const handleNext = () => {
    setActiveCellIndex(wrap(0, cells.length, activeCellIndex - 1));
  };

  const handlePrev = () => {
    setActiveCellIndex(wrap(0, cells.length, activeCellIndex + 1));
  };

  return (
    <motion.div
      className={style.root}
      ref={ref}
      variants={defaultRootVariants}
      initial="initial"
      animate={inView ? "animate" : "initial"}
      exit="exit"
    >
      <motion.div className={style.content} variants={contentVariants}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            className={style.cell}
            key={activeCell.id}
            variants={cellVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <motion.div
              className={style.imageContainer}
              variants={imageVariants}
            >
              {!!image && (
                <div className={style.imageWrapper}>
                  <Image
                    src={image.url}
                    className={style.image}
                    alt={activeCell.heading}
                    layout="fill"
                    objectFit="cover"
                    sizes="360px"
                  />
                </div>
              )}
            </motion.div>
            <motion.div className={style.text} variants={textVariants}>
              <motion.div
                className={style.quotes}
                variants={textElementVariants}
              >
                <Quotes />
              </motion.div>
              <motion.div
                className={style.quotation}
                variants={textElementVariants}
                dangerouslySetInnerHTML={{ __html: activeCell.quotation }}
              />
              <motion.div
                className={style.attribution}
                variants={textElementVariants}
              >
                {activeCell.attribution}
              </motion.div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
        {cells.length > 1 && (
          <div className={style.footer}>
            <div className={style.pager}>
              <Pager
                count={cells.length}
                activeIndex={activeCellIndex}
                onClick={setActiveCellIndex}
              />
            </div>
            <div className={style.pageControls}>
              <PageControls onNext={handleNext} onPrev={handlePrev} />
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Quotations;
