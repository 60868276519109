import clsx from "clsx";
import style from "components/Accordion.module.scss";
import { ArrowRight } from "components/Icons";
import Image from "next/legacy/image";
import { useState } from "react";

const Accordion = ({ items }) => {
  const [activeItem, setActiveItem] = useState(items[0]);

  const handleClick = (index) => {
    setActiveItem(items[index]);
  };

  const image = activeItem.image[0];

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.navigation}>
          {items.map((item, index) => {
            const itemClassName = clsx(style.navigationItem, {
              [style.navigationItem___isActive]: activeItem.id === item.id,
            });

            return (
              <button
                className={itemClassName}
                onClick={() => handleClick(index)}
                type="button"
                key={item.id}
              >
                <div className={style.navigationLabel}>{item.buttonLabel}</div>
                <div className={style.navigationIcon}>
                  <ArrowRight size={23} />
                </div>
              </button>
            );
          })}
        </div>
        <div key={activeItem.id} className={style.item}>
          {!!image && (
            <div className={style.image}>
              <Image
                src={image.url}
                className={style.img}
                alt={activeItem.heading}
                width={image.width}
                height={image.height}
              />
            </div>
          )}
          <div className={style.caption}>
            <div className={style.heading}>{activeItem.heading}</div>
            <div
              className={style.text}
              dangerouslySetInnerHTML={{ __html: activeItem.text }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
