import clsx from "clsx";

type SpacerProps = {
  size: "xs" | "sm" | "md" | "lg" | "xl";
};

const Spacer = ({ size }: SpacerProps) => {
  return (
    <div
      className={clsx("Spacer", `Spacer--${size}`, {
        "h-2 md:h-4 lg:h-6 2xl:h-8": size === "xs",
        "h-4 md:h-8 lg:h-12 2xl:h-16": size === "sm",
        "h-6 md:h-12 lg:h-16 2xl:h-20": size === "md",
        "h-8 md:h-16 lg:h-20 2xl:h-24": size === "lg",
        "h-12 md:h-24 lg:h-28 2xl:h-32": size === "xl",
      })}
    ></div>
  );
};

export default Spacer;
