import clsx from "clsx";
import Card from "components/Card";
import React from "react";

const Cards = ({ cards, backgroundTheme = "light", size = "base" }) => {
  return (
    <div
      className={clsx(
        "Cards",
        "relative z-10 after:absolute after:bottom-0 after:left-0 after:right-0 after:top-36 after:block after:content-['']",
        {
          "after:bg-gray-100": backgroundTheme === "light",
          "after:bg-gray-200": backgroundTheme === "dark",
        },
      )}
    >
      <div
        className={clsx(
          "Cards__content",
          "relative z-10 mx-auto max-w-screen-2xl justify-center px-6 lg:px-16",
        )}
      >
        <div
          className={clsx(
            "Cards__cards",
            "-mx-4 -my-4 flex flex-wrap justify-center pb-16 pt-10",
          )}
        >
          {cards.map((card) => {
            const image = card.image?.[0];

            return (
              <div
                key={card.id}
                className={clsx(
                  "Cards__card",
                  "w-full px-4 py-4 sm:w-1/2 lg:w-1/3",
                )}
              >
                <Card
                  image={image}
                  heading={card.heading}
                  textOverlay={card.textOverlay}
                  caption={card.caption}
                  target={card.target}
                  footer={card.footer}
                  size={size}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cards;
