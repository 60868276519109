import Caption from "@/components/Caption";
import style from "@/components/SingleImage.module.scss";
import clsx from "clsx";
import Image from "next/image";
import React from "react";
import { useInView } from "react-intersection-observer";

const SingleImage = ({ image, caption }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  if (!image) return null; // if no image

  return (
    <div
      className={clsx(
        "SingleImage",
        "mx-auto opacity-0 transition-opacity duration-700",
        {
          "opacity-100": inView,
        },
      )}
      ref={ref}
    >
      <div className={clsx("SingleImage__content", "relative", {})}>
        <Image
          src={image.url}
          className={style.image}
          alt={image.title}
          width={image.width}
          height={image.height}
        />

        {!!caption && (
          <div className={style.caption}>
            <Caption caption={caption} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleImage;
