import Caption from "@/components/Caption";
import PageControls from "@/components/PageControls";
import Pager from "@/components/Pager";
import { isColorDark } from "@/lib/helpers";
import clsx from "clsx";
import Image from "next/legacy/image";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

const Slideshow = ({ images, caption, aspectRatio, baseColor }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const handleCellSelect = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };

  const handleNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  const handlePrev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const className = clsx(
    "relative overflow-hidden w-full z-10 px-6 md:px-10 lg:px-16",
    {
      "pb-12": !!caption,
    }
  );

  return (
    <div className={className}>
      <div
        className={clsx("relative z-10 mx-auto", {
          "max-w-3xl":
            aspectRatio === "landscape" || aspectRatio === "landscape169",
          "max-w-sm": aspectRatio === "square" || aspectRatio === "portrait",
        })}
      >
        <Swiper
          className={clsx("relative overflow-hidden rounded-lg")}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          loop={true}
          ref={swiperRef}
        >
          {images.map((image, index) => (
            <SwiperSlide key={image.id}>
              <Cell image={image} aspectRatio={aspectRatio} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={clsx(
            "relative flex h-16 w-full items-center justify-start"
          )}
        >
          <div className={clsx("flex py-4")}>
            <Pager
              count={images.length}
              onClick={handleCellSelect}
              theme={isColorDark(baseColor) ? "light" : "dark"}
              activeIndex={activeIndex}
            />
          </div>
          <div className={clsx("absolute right-0")}>
            <PageControls
              onNext={handleNext}
              onPrev={handlePrev}
              theme={isColorDark(baseColor) ? "light" : "dark"}
            />
          </div>
        </div>
        {caption && (
          <div className={clsx("h-10 w-full")}>
            <Caption
              caption={caption}
              theme={isColorDark(baseColor) ? "light" : "dark"}
            />
          </div>
        )}
      </div>
      {!!baseColor && (
        <div
          className={clsx(
            "absolute top-[calc(50%-40px)] left-0 right-0 bottom-0",
            {
              "top-[calc(50%-100px)]": withCaption,
            }
          )}
          style={{ backgroundColor: baseColor }}
        />
      )}
    </div>
  );
};

const Cell = ({ image, aspectRatio }) => {
  return (
    <div
      className={clsx("relative bg-gray-50", {
        "aspect-3/4": aspectRatio === "portrait",
        "aspect-square": aspectRatio === "square",
        "aspect-golden": aspectRatio === "landscape",
        "aspect-16/9": aspectRatio === "landscape169",
      })}
    >
      <Image
        src={image.url}
        alt={image.title}
        layout="fill"
        objectFit="cover"
      />
    </div>
  );
};

export default Slideshow;
