import clsx from "clsx";
import style from "components/Caption.module.scss";
import React from "react";

const Caption = ({ caption = null, theme = "dark" }) => {
  const className = clsx(style.root, {
    [style.root___dark]: theme === "dark",
    [style.root___light]: theme === "light",
  });

  return (
    <div className={className}>
      <div
        className={style.content}
        dangerouslySetInnerHTML={{ __html: caption }}
      />
    </div>
  );
};

export default Caption;
