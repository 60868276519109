import clsx from "clsx";
import style from "components/PageControls.module.scss";
import React from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

const PageControls = ({ onNext, onPrev, theme = "dark" }) => {
  const className = clsx(style.root, {
    [style.root___dark]: theme === "dark",
    [style.root___light]: theme === "light",
  });

  return (
    <div
      className={clsx("flex items-center", {
        "text-gray-900": theme === "dark",
        "text-white": theme === "light",
      })}
    >
      <button
        className={clsx(style.control, style.control___prev)}
        type="button"
        onClick={onPrev}
      >
        <ArrowLeft className={clsx("w-5 lg:w-6 h-5 lg:h-6 stroke-[1.5]")} />
      </button>
      <button
        className={clsx(style.control, style.control___next)}
        type="button"
        onClick={onNext}
      >
        <ArrowRight className={clsx("w-5 lg:w-6 h-5 lg:h-6 stroke-[1.5]")} />
      </button>
    </div>
  );
};

export default PageControls;
