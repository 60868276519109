import Target from "@/components/Target";
import clsx from "clsx";
import Image from "next/legacy/image";
import React from "react";
import { ArrowRight } from "react-feather";
import { useInView } from "react-intersection-observer";
import BodyText from "./BodyText";

const ImageAndText = ({
  image,
  imagePosition = "left",
  heading,
  text,
  target,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div
      className={clsx(
        "ImageAndText ",
        "container opacity-0 transition-opacity duration-700",
        {
          "opacity-100": inView,
        },
      )}
      ref={ref}
    >
      <div
        className={clsx("ImageAndText__content", "flex flex-col", {
          "lg:flex-row lg:items-center": imagePosition === "left",
          "lg:flex-row-reverse lg:items-center": imagePosition === "right",
        })}
      >
        <div className={clsx("ImageAndText__imageWrapper", "lg:w-7/12")}>
          {!!image && !!image.url && (
            <Image
              src={image.url}
              className={clsx("ImageAndText__image", "rounded-lg")}
              alt={image.title}
              width={image.width}
              height={image.height}
            />
          )}
        </div>
        <div
          className={clsx("ImageAndText__caption", "lg:w-5/12", {
            "lg:pr-16 lg:text-right": imagePosition === "right",
            "lg:pl-16": imagePosition === "left",
          })}
        >
          <h3
            className={clsx(
              "ImageAndText__heading",
              "mb-2 pt-5 text-3xl font-medium lg:pt-0 lg:text-4xl",
            )}
          >
            {heading}
          </h3>
          <div className={clsx("ImageAndText__text")}>
            <BodyText text={text} size="large" />
          </div>
          {!!target && target.url && (
            <div className={clsx("ImageAndText__targetWrapper", "")}>
              <Target
                className={clsx(
                  "ImageAndText__target",
                  "group relative mt-5 inline-flex items-center space-x-2 text-lg text-gray-400 hover:text-gray-700",
                )}
                target={target}
              >
                <div className={clsx("")}>{target.text}</div>
                <div
                  className={clsx(
                    "relative top-0.5 transition group-hover:translate-x-0.5",
                  )}
                >
                  <ArrowRight strokeWidth={1.5} size={18} />
                </div>
              </Target>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageAndText;
