import clsx from "clsx";
import Caption from "components/Caption";
import dynamic from "next/dynamic";
import React, { useState } from "react";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const VideoEmbed = ({ videoUrl = null, caption = null }) => {
  const [isReady, setIsReady] = useState(false);

  return (
    <div className={clsx("VideoEmbed")}>
      <div
        className={clsx("VideoEmbed__content", "mx-auto max-w-5xl w-full", {
          invisible: !isReady,
          visible: isReady,
        })}
      >
        <div className={clsx("VideoEmbed__embed", "aspect-video relative")}>
          <ReactPlayer
            url={videoUrl}
            className={clsx("VideoEmbed__player", "absolute inset-0")}
            width="100%"
            height="100%"
            playsinline
            controls
            onReady={() => {
              setIsReady(true);
            }}
            config={{
              youtube: {
                playerVars: { showinfo: 0 },
              },
              vimeo: {
                playerOptions: {
                  byline: false,
                  color: "#ffffff",
                  transparent: true,
                },
              },
            }}
          />
        </div>
        {!!caption && (
          <div className={clsx("VideoEmbed__caption")}>
            <Caption caption={caption} />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoEmbed;
