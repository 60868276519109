import Button from "@/components/Button";
import Target from "@/components/Target";
import { widont } from "@/lib/helpers";
import clsx from "clsx";
import Image from "next/legacy/image";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useInView } from "react-intersection-observer";

const Panel = ({
  heading,
  image,
  caption,
  textColor,
  target,
  imagePosition = "left",
  imageSize = "base", // base, lg
}) => {
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
  });

  return (
    <div className={clsx("Panel", "")} ref={ref}>
      <div
        className={clsx(
          "Panel__content",
          "mx-auto flex max-w-screen-2xl flex-col overflow-hidden opacity-0 transition-opacity duration-700 ",
          {
            "md:flex-row md:items-center": imagePosition === "left",
            "md:flex-row-reverse md:items-center": imagePosition === "right",
            "opacity-100": inView,
          },
        )}
      >
        {!!image && (
          <div
            className={clsx(
              "Panel__imageContainer",
              "w-full px-6 py-10  md:px-10 lg:px-16 lg:py-16",
              {
                "md:w-1/2": imageSize === "base",
                "md:w-3/5": imageSize === "lg",
              },
            )}
          >
            <Target
              className={clsx(
                "Panel__imageWrapper",
                "overflow-hidden rounded-lg",
              )}
              target={target}
            >
              <Image
                className={clsx("Panel__image")}
                src={image.url}
                width={image.width}
                height={image.height}
                layout="responsive"
                alt={heading}
              />
            </Target>
          </div>
        )}

        <div
          className={clsx(
            "Panel__text",
            "px-6 pt-0 pb-10 md:w-1/2 md:px-10 md:pt-20 md:pb-10 lg:px-16 lg:pb-16",
            {
              "md:w-1/2": imageSize === "base",
              "md:w-2/5": imageSize === "lg",
            },
          )}
          style={{ color: textColor }}
        >
          <Target
            className={clsx(
              "Panel__heading",
              "mb-4 inline-block font-medium lg:mb-5 ",
              {
                "text-3xl md:text-4xl xl:text-5xl": imageSize === "base",
                "text-3xl md:text-3xl xl:text-4xl": imageSize === "lg",
              },
            )}
            target={target}
            style={{ color: textColor }}
          >
            {widont(heading)}
          </Target>
          <div
            className={clsx("Panel__caption", "lg:font-light ", {
              "text-xl lg:text-2xl xl:text-3xl": imageSize === "base",
              "text-xl lg:text-xl xl:text-2xl": imageSize === "lg",
            })}
            style={{ color: textColor }}
          >
            {ReactHtmlParser(caption)}
          </div>
          {!!target.url && (
            <Target
              className={clsx("Panel__button", "inline-block pt-7 lg:pt-10")}
              target={target}
            >
              <Button label={target.text} textColor={textColor} />
            </Target>
          )}
        </div>
      </div>
    </div>
  );
};

export default Panel;
