import clsx from "clsx";
import React from "react";
import ReactHtmlParser from "react-html-parser";

const BodyText = ({ text, size = "base" }) => {
  return (
    <div className={clsx("BodyText", "")}>
      <div
        className={clsx(
          "BodyText__content",
          "prose w-full text-gray-600 prose-a:decoration-gray-400 prose-a:decoration-1 prose-a:underline-offset-2 hover:prose-a:decoration-gray-500 prose-strong:font-medium",
          {
            "prose-2xl": size === "large" || size === "lg",
            "prose-xl": size === "normal" || size === "base",
            "prose-lg": size === "small" || size === "sm",
          },
        )}
      >
        {ReactHtmlParser(text)}
      </div>
    </div>
  );
};

export default BodyText;
